import { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  DisplayDataProperties,
  TargetAnnotationProperties,
  commonOptions,
  whiteBackgroundPlugin,
} from "../../../../BehaviorReduction/Actions/BehaviorReductionGraph/graphHelpers";

export const LineDisplay = ({
  scaleX,
  startDate,
  displayData,
  scaleY,
  annotationLines,
}: {
  scaleX: any;
  startDate: string;
  displayData: Array<DisplayDataProperties>;
  scaleY: any;
  annotationLines: TargetAnnotationProperties[];
}) => {
  const [xLineAverage, setXLineAverage] = useState<number>(0);
  const [splitData, setSplitData] = useState<Array<DisplayDataProperties[]>>(
    []
  );

  useEffect(() => {
    const length = displayData.length;
    const total = displayData.reduce((total, item) => total + item.y, 0);
    setXLineAverage(total / length);

    if (annotationLines.length > 0) {
      const annotationDates = annotationLines
        .map((annotation) => new Date(annotation.date).getTime())
        .sort((a, b) => a - b);

      const splitDataArray: Array<DisplayDataProperties[]> = [];
      let currentIndex = 0;

      annotationDates.forEach((annotationDate) => {
        const nextIndex = displayData.findIndex(
          (item) => new Date(item.x).getTime() > annotationDate
        );

        if (nextIndex !== -1) {
          splitDataArray.push(displayData.slice(currentIndex, nextIndex));
          currentIndex = nextIndex;
        }
      });

      splitDataArray.push(displayData.slice(currentIndex));
      setSplitData(splitDataArray);
    } else {
      setSplitData([displayData]);
    }
  }, [displayData, annotationLines]);

  return (
    <Line
      options={{
        ...commonOptions,
        responsive: true,
        scales: { x: scaleX, y: scaleY },
      }}
      data={{
        labels: [
          new Date(startDate),
          new Date().setDate(new Date().getDate() + 2),
        ],
        datasets: [
          ...splitData.map((dataPart, index) => ({
            label: `${index + 1}: ${
              !!dataPart.length ? dataPart[dataPart.length - 1].x : ""
            }`,
            data: dataPart,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          })),
          {
            label: "Average",
            data: Array(displayData.length).fill(xLineAverage),
            fill: false,
            borderColor: "red",
            borderDash: [5, 5],
          },
        ],
      }}
    />
  );
};

export const LineDownload = ({
  scaleX,
  startDate,
  displayData,
  scaleY,
  annotationLines,
}: {
  scaleX: any;
  startDate: string;
  displayData: Array<DisplayDataProperties>;
  scaleY: any;
  annotationLines: TargetAnnotationProperties[];
}) => {
  const [xLineAverage, setXLineAverage] = useState<number>(0);
  const [splitData, setSplitData] = useState<Array<DisplayDataProperties[]>>(
    []
  );

  useEffect(() => {
    const length = displayData.length;
    const total = displayData.reduce((total, item) => total + item.y, 0);
    setXLineAverage(total / length);

    if (annotationLines.length > 0) {
      const annotationDates = annotationLines
        .map((annotation) => new Date(annotation.date).getTime())
        .sort((a, b) => a - b);

      const splitDataArray: Array<DisplayDataProperties[]> = [];
      let currentIndex = 0;

      annotationDates.forEach((annotationDate) => {
        const nextIndex = displayData.findIndex(
          (item) => new Date(item.x).getTime() > annotationDate
        );

        if (nextIndex !== -1) {
          splitDataArray.push(displayData.slice(currentIndex, nextIndex));
          currentIndex = nextIndex;
        }
      });

      splitDataArray.push(displayData.slice(currentIndex));
      setSplitData(splitDataArray);
    } else {
      setSplitData([displayData]);
    }
  }, [displayData, annotationLines]);

  return (
    <Line
      options={{
        ...commonOptions,
        responsive: false,
        scales: { x: scaleX, y: scaleY },
      }}
      style={{ display: "none" }}
      plugins={[whiteBackgroundPlugin]}
      data={{
        labels: [new Date(startDate), new Date()],
        datasets: [
          ...splitData.map((dataPart, index) => ({
            label: `${index + 1}: ${
              !!dataPart.length ? dataPart[dataPart.length - 1].x : ""
            }`,
            data: dataPart,
            borderColor: "rgb(53, 162, 235)",
            backgroundColor: "rgba(53, 162, 235, 0.5)",
          })),
          {
            label: "Average",
            data: Array(displayData.length).fill(xLineAverage),
            fill: false,
            borderColor: "red",
            borderDash: [5, 5],
          },
        ],
      }}
      height={300}
      width={500}
      id="stackId"
    />
  );
};
