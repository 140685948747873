import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomSelect,
  Picker,
  CustomButton,
  Text,
} from "../../../shared/uiComponents";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import { InfoRow } from "../../../components/StyledComponents";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { TimeInputsWrapper } from "../../Reports/helpers";
import { months } from "../Helpers/constants";
import { DayProperties } from "../Helpers/interfaces";
import { useResetCalendarData } from "../Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  adminCreateEvent,
  setAdminAddEvent,
} from "../../../redux/State/clientSlice/calendarSlice";
import { AdminCreateEventProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import {
  getTherapists,
  getUserClients,
} from "../../../redux/State/clientSlice/userClientSlice";

const AdminAddEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.addAdmin);
  const selectedDay = useSelector((state) => state.calendar.selectedDay);

  const closeHandler = () => {
    dispatch(setAdminAddEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      onClose={closeHandler}
      title={`Add calendar event to ${months[selectedDay.month]} ${
        selectedDay.day
      } ${selectedDay.year}`}
      Content={() => (
        <AddContent selectedDay={selectedDay} onClose={closeHandler} />
      )}
    />
  );
};

const AddContent = ({
  selectedDay,
  onClose,
}: {
  selectedDay: DayProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();
  const [params] = useSearchParams();
  const userParamId = params.get("userId");
  const clientParamId = params.get("clientId");
  const clientName = params.get("clientName");
  const userName = params.get("userName");

  const [pageSize, setPageSize] = useState<number>(8);
  const [sessionTypeId, setSessionTypeId] = useState<string>("0");
  const [clientId, setClientId] = useState<string>("0");
  const [userId, setUserId] = useState<string>("0");
  const [clientList, setClientList] = useState<Array<SelectDataProperties>>([]);
  const [userList, setUserList] = useState<Array<SelectDataProperties>>([]);
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(null);

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const userClients = useSelector((state) => state.userClient.userClients);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);

  const { hasError, message } = useCompareDates(startTimeValue, endTimeValue);

  useEffect(() => {
    if (!userParamId) return;
    dispatch(
      getUserClients({
        userId: userParamId,
        page: "1",
        pageSize: pageSize.toString(),
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (!response.query) return;

        const clientList = response.query.map((client) => ({
          id: client.id,
          name: client.fullName,
        }));
        if (!clientList.length) {
          setClientId("");
          return;
        }
        setClientList([{ id: "0", name: "Select Client" }, ...clientList]);
      })
      .catch(errorNormalizer);
  }, [userId, userParamId, pageSize, dispatch]);

  useEffect(() => {
    if (!clientParamId) return;
    dispatch(getTherapists(clientParamId))
      .then(unwrapResult)
      .then((response) => {
        if (!response) return;
        const data = response.map((user) => ({
          id: user.id,
          label: user.fullName,
        }));
        setUserList([{ id: "0", name: "Select Provider" }, ...data]);
      })
      .catch(errorNormalizer);
  }, [clientParamId, dispatch]);

  const onClientChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setClientId(value);
  };

  const onUserChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setUserId(value);
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSessionTypeId(value);
  };

  useEffect(() => {
    if (!!startTimeValue) {
      setEndTimeValue(startTimeValue.add(1, "h"));
    }
  }, [startTimeValue]);

  const addSession = () => {
    const { day, month, year } = selectedDay;
    if (!startTimeValue || !endTimeValue || !days.length) return;
    const hour = startTimeValue.hour();
    const minute = startTimeValue.minute();
    const dateValue = new Date(year, month, day, hour, minute);
    const startTime = dayjs(dateValue).format("YYYY-MM-DDTHH:mm");

    const endHour = endTimeValue.hour();
    const endMinute = endTimeValue.minute();
    const endTime = dayjs(
      new Date(year, month, day, endHour, endMinute)
    ).format("YYYY-MM-DDTHH:mm");

    if (!startTime || !endTime || !userId) return;

    const data: AdminCreateEventProperties = {
      userId: !!userParamId ? userParamId : userId,
      clientId: !!clientParamId ? clientParamId : clientId,
      date: dateNormalizer(dayjs(dateValue)),
      startTime,
      endTime,
      sessionTypeId: parseInt(sessionTypeId),
    };

    dispatch(adminCreateEvent(data))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Created");
        onClose();
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      {userName && (
        <InfoRow style={{ marginBottom: "8px" }}>
          <Text title={"Provider Name:"} size="smallBold" />
          <Text title={userName} />
        </InfoRow>
      )}
      {clientName && (
        <InfoRow style={{ marginBottom: "8px" }}>
          <Text title={"Client Name:"} size="smallBold" />
          <Text title={clientName} />
        </InfoRow>
      )}

      {!!clientParamId && !userParamId && (
        <CustomSelect
          label="Provider"
          data={userList}
          value={userId}
          setValue={onUserChange}
          className={"marginBottom16"}
        />
      )}
      {!!userParamId && !clientParamId && (
        <CustomSelect
          label="Client"
          data={clientList}
          value={clientId}
          setValue={onClientChange}
          className={"marginBottom16"}
          loadMore={{
            activate: !!userClients.totalNumberOfItems
              ? userClients.totalNumberOfItems > 8
              : false,
            setSize: setPageSize,
          }}
        />
      )}
      {!!sessionTypes && (
        <CustomSelect
          label="Session type"
          data={sessionTypes}
          value={sessionTypeId}
          setValue={onTypeChange}
          className={"marginBottom16"}
        />
      )}
      <TimeInputsWrapper className="marginBottom16">
        <Picker.CustomTime
          label="Start time"
          value={startTimeValue}
          onChange={setStartTimeValue}
        />
        <Picker.CustomTime
          label="End time"
          value={endTimeValue}
          onChange={setEndTimeValue}
          error={hasError}
          errorMessage={message}
        />
      </TimeInputsWrapper>
      <CustomButton
        onClick={addSession}
        title={"Add calendar event"}
        disabled={
          !startTimeValue ||
          !endTimeValue ||
          (!clientId && !clientParamId) ||
          (!userId && !userParamId) ||
          hasError ||
          sessionTypeId === "0"
        }
      />
    </>
  );
};

export default AdminAddEvent;
