import { useEffect, useReducer, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";
import {
  SoapNoteParentTrainingType,
  UpdateParentTrainingTypeProperties,
} from "../SoapNoteProperties/parentTrainingTypeProperties";
import ParentTrainingData from "./parentTrainingData";
import {
  ACTIONS,
  initialParentTrainingState,
  parentTrainingReducer,
} from "./parentTrainingReducer";
import {
  SOAP_NOTES,
  SaveSoapNote,
  SoapNoteButtons,
  hasAnyData,
  isActionAllowed,
  removeSavedSoapNoteData,
  ForcedOperation,
} from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getSoapNote,
  updateParentTraining,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../redux/State/clientSlice/sessionSlice";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const UpdateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();

  const [soapNoteStatus, setSoapNoteStatus] = useState<SoapNotesStatuses>(0);
  const { resetData } = useResetCalendarData();

  const [data, setData] = useReducer(
    parentTrainingReducer,
    initialParentTrainingState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");

    if (!reportId || !clientId || !sessionId) return;

    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);
    if (!!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          directParentTrainingResponses,
          participantResponses,
          location,
          date,
          startTime,
          endTime,
          status,
        } = response as SoapNoteParentTrainingType;
        const directParentTrainings = directParentTrainingResponses?.map(
          (x) => x.id
        );
        const participants = participantResponses?.map((x) => x.id);

        setSoapNoteStatus(status.status);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setDirectParentTraining,
          payload: directParentTrainings,
        });

        setData({
          type: ACTIONS.setAttendance,
          payload: participants,
        });

        if (!location) return;
        setData({
          type: ACTIONS.setLocationId,
          payload: location.id,
        });
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    if (!reportId || !clientId || !data) return;
    const { date, startTime, endTime } = sessionInfo;
    const allData: UpdateParentTrainingTypeProperties = {
      ...data,
      date,
      startTime,
      endTime,
      reportId,
      submit,
      forcedOperation,
    };
    dispatch(updateParentTraining({ clientId, reportId, data: allData }))
      .then(unwrapResult)
      .then(() => {
        removeSavedSoapNoteData(data.sessionId);
        resetData();
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  useEffect(() => {
    if (!hasAnyData(data)) return;
    const allData = {
      ...data,
      submit: false,
    };
    SaveSoapNote(allData);
  }, [data]);

  return (
    <>
      <ParentTrainingData
        setData={setData}
        data={data}
        disabled={!isActionAllowed(soapNoteStatus)}
      />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
      <ForcedOperation submit={() => updateSoapNoteHandler(true)} />
    </>
  );
};

export default UpdateSoapNote;
