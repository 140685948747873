import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";

import { CustomSelect, Text } from "../../../shared/uiComponents";
import { HeaderWrapper } from "./helpers";
import Assessment from "../../SOAPNote/Assessment";
import TreatmentPlanning from "../../SOAPNote/TreatmentPlanning";
import BehaviorTreatment from "../../SOAPNote/BehaviorTreatment";
import DirectSupervision from "../../SOAPNote/DirectSupervision";
import BehaviorTreatmentModification from "../../SOAPNote/BehaviorTreatmentModification";
import ParentTraining from "../../SOAPNote/ParentTraining";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getSessionInfo,
  getSessionTypes,
} from "../../../redux/State/clientSlice/sessionSlice";

const EditSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const [service, setService] = useState<string>("");

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const { client, date } = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    if (!!sessionTypes.length) return;
    dispatch(getSessionTypes());
  }, [dispatch, sessionTypes]);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;

    dispatch(getSessionInfo(sessionId));
  }, [params, dispatch]);

  useEffect(() => {
    const type = params.get("type");
    if (!type) return;
    setService(type);
  }, [params]);

  return (
    <div
      className="container"
      style={{ flexDirection: "column", maxWidth: "900px" }}
    >
      <HeaderWrapper className="title marginBottom16">
        <Text title="SOAP Note" size="mediumBold" />
        <Text title={`Date: ${dayjs(date).format("MM/DD/YYYY")}`} />
        <Text title={client?.fullName} size="smallBold" />
      </HeaderWrapper>

      <CustomSelect
        label="Service:"
        value={service}
        data={sessionTypes}
        setValue={() => {}}
        disabled={true}
      />
      <div className="marginBottom16" />

      {service === "1" && <Assessment />}
      {service === "2" && <TreatmentPlanning />}
      {service === "3" && <BehaviorTreatment />}
      {service === "4" && <DirectSupervision />}
      {service === "5" && <BehaviorTreatmentModification />}
      {service === "6" && <ParentTraining />}
      {service === "7" && <TreatmentPlanning />}
    </div>
  );
};


export default EditSoapNote;
