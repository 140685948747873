import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { black500, blue100, red500 } from "../../shared/Helpers/colors";
import Text from "../../shared/uiComponents/Text";
import { useScreenWidth } from "../../shared/Helpers/hooks";

import InfoEvent from "./Actions/InfoEvent/index";
import { findColor, sortByTime } from "./Helpers/functions";
import { DayProperties } from "./Helpers/interfaces";
import { Session, SessionHeader } from "./Helpers/session";
import { weeks } from "./Helpers/constants";

import { DispatchProperties, useSelector } from "../../redux/store";
import { setSelectedDay } from "../../redux/State/clientSlice/calendarSlice";
import { SoapNotesStatuses } from "../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const MonthView = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const showCompletedSession =
    params.get("showCompleted") === "true" ? true : false;
  const showDraftsOnly = params.get("showDraftsOnly") === "true" ? true : false;
  const screenWidth = useScreenWidth();

  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const events = useSelector((state) => state.calendar.events);

  const daysMapper = (item: DayProperties, index: number) => {
    const today = new Date();
    const currentDay = today.getDate();
    const currentMonth = today.getMonth();

    const { backgroundColor } = findColor({
      item,
      index,
      screenWidth,
    });

    const selectDayHandler = (item: DayProperties) => {
      dispatch(setSelectedDay(item));
    };

    return (
      <div
        key={index}
        className={"day"}
        style={{
          backgroundColor,
          outline:
            currentDay === item.day && currentMonth === item.month
              ? `5px solid ${blue100}`
              : "none",
        }}
        onClick={() => selectDayHandler(item)}
      >
        <SessionHeader item={item} index={index} />
        <div className={"sessionWrapper"}>
          {!!events &&
            !!events.length &&
            [...events]
              .sort((a, b) => sortByTime({ a, b }))
              .filter((x) =>
                showCompletedSession
                  ? x.isCompleted &&
                    x.reportStatus?.status === SoapNotesStatuses.submitted
                  : true
              )
              .filter((x) =>
                showDraftsOnly
                  ? x.reportStatus?.status === SoapNotesStatuses.draft
                  : true
              )
              .map(
                (res) =>
                  new Date(res.startTime).getUTCDate() === item.day &&
                  new Date(res.startTime).getUTCMonth() === item.month && (
                    <Session key={res.id} event={res} className="session" />
                  )
              )}
        </div>
      </div>
    );
  };

  return (
    <div className={"yearView"}>
      <div className={"headers"}>
        {weeks.map((day, index) => (
          <div key={index} className={"header"}>
            <Text
              title={day}
              size={"smallBold"}
              textColor={day === "Sun" ? red500 : black500}
            />
          </div>
        ))}
      </div>
      <div className="daysWrapper">{days.map(daysMapper)}</div>
      <InfoEvent />
    </div>
  );
};

export default MonthView;
