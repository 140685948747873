import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import Picker from "../../../../../../../shared/uiComponents/DateAndTime";
import {
  CustomButton,
  CustomAlert,
  CustomInput,
} from "../../../../../../../shared/uiComponents";
import { errorNormalizer, dateNormalizer } from "../../../../../../../shared/Helpers/functions";

import { DispatchProperties } from "../../../../../../../redux/store";
import {
  addBehaviorAnnotation,
  getBehaviorReductionAllAnnotations,
} from "../../../../../../../redux/State/clientSlice/graphAnnotationSlice";
import { BehaviorAnnotationProperties } from "../../../../../../../redux/API/ClientAPIHelpers/graphAnnotationProperties";

const AddAnnotationPopup = ({
  behaviorReductionId,
}: {
  behaviorReductionId: string;
}) => {
  const [createAnnotation, setCreateAnnotation] = useState<boolean>(false);

  return (
    <>
      <CustomButton
        title="Add Annotation"
        onClick={() => setCreateAnnotation(true)}
      />
      <CustomAlert
        title="Add Annotation"
        onClose={() => setCreateAnnotation(false)}
        open={createAnnotation}
        popupHeight="550px"
        Content={({ onClose }) => (
          <AddAnnotationContent
            behaviorReductionId={behaviorReductionId}
            onClose={onClose}
          />
        )}
      />
    </>
  );
};

const AddAnnotationContent = ({
  behaviorReductionId,
  onClose,
}: {
  behaviorReductionId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const today = new Date();
  const todayDayjs = dayjs(today);
  
  const [currentDate, setCurrentDate] = useState<Dayjs>(todayDayjs);
  const [instructions, setInstructions] = useState<string>("");

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInstructions(value);
  };

  const hadleOnChangeDate = (date: Dayjs | null) => {
    if (!date) return;
    setCurrentDate(date);
  };

  const handleCreateAnnotation = () => {
    if (instructions.length < 4 || !currentDate) return;

    const date = dateNormalizer(currentDate);

    const newData: BehaviorAnnotationProperties = {
      behaviorId: behaviorReductionId,
      note: instructions,
      date,
    };
    dispatch(addBehaviorAnnotation(newData))
      .then(unwrapResult)
      .then(() => {
        dispatch(getBehaviorReductionAllAnnotations(behaviorReductionId));
      })
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  return (
    <>
      <div className="description">
        <CustomInput
          label="Description"
          value={instructions}
          setValue={onTargetInstructionsChange}
          multiline={{ multiline: true, rowCount: 4 }}
          className="marginBottom16"
          error={instructions.length < 4}
          maxLength={250}
          errorMessage={instructions.length < 4 ? "Please add description" : ""}
        />
      </div>
      <Picker.CustomDate
        label="Date"
        value={currentDate}
        onChange={hadleOnChangeDate}
        className="marginBottom16"
      />
      <div className="btns-popup">
        <CustomButton
          title={"Save"}
          onClick={handleCreateAnnotation}
          disabled={instructions.length < 4 || !currentDate}
        />
        <CustomButton title={"Cancel"} secondaryButton onClick={onClose} />
      </div>
    </>
  );
};

export default AddAnnotationPopup;
