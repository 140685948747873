import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";
import { SaveParentTrainingTypeProperties } from "../SoapNoteProperties/parentTrainingTypeProperties";
import {
  ACTIONS,
  initialParentTrainingState,
  parentTrainingReducer,
} from "./parentTrainingReducer";
import {
  hasAnyData,
  removeSavedSoapNoteData,
  SaveSoapNote,
  SOAP_NOTES,
  SoapNoteButtons,
} from "../helpers";
import ParentTrainingData from "./parentTrainingData";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { saveParentTraining } from "../../../redux/State/clientSlice/soapNoteSlice";

const CreateSoapNote = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const paramsStatus = params.get("soapNoteStatus");
  const soapNoteStatus = !!paramsStatus ? parseInt(paramsStatus) : 0;

  const [data, setData] = useReducer(
    parentTrainingReducer,
    initialParentTrainingState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    const notes = window.localStorage.getItem(SOAP_NOTES);
    const soapNotes: Array<any> = notes ? JSON.parse(notes) : [];
    const savedData = soapNotes.find((x) => x.sessionId === sessionId);

    if (!sessionId) return;
    if (!hasAnyData(data) && !!savedData) {
      setData({
        type: ACTIONS.setState,
        payload: savedData,
      });
      return;
    }

    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData = {
      ...data,
      sessionId,
      date,
      startTime,
      endTime,
      printedName: user.fullName.toUpperCase(),
      submit: false,
      client,
    };
    SaveSoapNote(allData);
  }, [data, params, sessionInfo]);

  const createSoapNoteHandler = (submit: boolean) => {
    const sessionId = params.get("sessionId");
    if (!sessionId || !data) return;
    const { date, startTime, endTime, user, client } = sessionInfo;
    const allData: SaveParentTrainingTypeProperties = {
      ...data,
      date,
      startTime,
      endTime,
      printedName: `${user.firstName} ${user.lastName}`.toUpperCase(),
      submit,
      sessionId,
    };
    dispatch(
      saveParentTraining({
        clientId: client.id,
        data: allData,
      })
    )
      .then(unwrapResult)
      .then(() => {
        removeSavedSoapNoteData(sessionId);
        resetData();
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <div>
      <ParentTrainingData data={data} setData={setData} />
      <SoapNoteButtons
        status={soapNoteStatus}
        save={() => createSoapNoteHandler(false)}
        submit={() => createSoapNoteHandler(true)}
      />
    </div>
  );
};
export default CreateSoapNote;
