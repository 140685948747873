import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";

import Picker from "../../../../../../../../shared/uiComponents/DateAndTime";
import {
  CustomButton,
  CustomAlert,
  CustomInput,
} from "../../../../../../../../shared/uiComponents";
import {
  errorNormalizer,
  dateNormalizer,
} from "../../../../../../../../shared/Helpers/functions";

import {
  addAnnotation,
  getTargetAllAnnotations,
} from "../../../../../../../../redux/State/clientSlice/graphAnnotationSlice";
import { DispatchProperties } from "../../../../../../../../redux/store";

const AddAnnotationPopup = ({ targetId }: { targetId: string }) => {
  const [createAnnotation, setCreateAnnotation] = useState<boolean>(false);

  return (
    <>
      <CustomButton
        title="Add Annotation"
        onClick={() => setCreateAnnotation(true)}
      />
      <CustomAlert
        title="Add Annotation"
        onClose={() => setCreateAnnotation(false)}
        open={createAnnotation}
        Content={({ onClose }) => (
          <AddAnnotationContent targetId={targetId} onClose={onClose} />
        )}
        popupHeight="550px"
      />
    </>
  );
};

const AddAnnotationContent = ({
  targetId,
  onClose,
}: {
  targetId: string;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const today = new Date();
  const todayDayjs = dayjs(today);

  const [currentDate, setCurrentDate] = useState<Dayjs>(todayDayjs);
  const [instructions, setInstructions] = useState<string>("");

  const onTargetInstructionsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setInstructions(value);
  };

  const hadleOnChangeDate = (date: any) => {
    setCurrentDate(date);
  };

  const handleCreateAnnotation = () => {
    if (instructions.length < 4 || !currentDate) return;
    const date = dateNormalizer(currentDate);

    const newData = {
      targetId: targetId,
      note: instructions,
      date,
    };
    dispatch(addAnnotation(newData))
      .then(unwrapResult)
      .then(() => {
        dispatch(getTargetAllAnnotations(targetId));
      })
      .catch(errorNormalizer);
    onClose();
  };

  return (
    <>
      <Picker.CustomDate
        label="Date"
        value={currentDate}
        onChange={hadleOnChangeDate}
        className="marginBottom16"
      />
      <div className="description">
        <CustomInput
          label="Description"
          value={instructions}
          setValue={onTargetInstructionsChange}
          multiline={{ multiline: true, rowCount: 4 }}
          className="marginBottom16"
          error={instructions.length < 4}
          maxLength={250}
          errorMessage={instructions.length < 4 ? "Please add description" : ""}
        />
      </div>
      <div className="btns-popup">
        <CustomButton
          title={"Save"}
          onClick={handleCreateAnnotation}
          disabled={instructions.length < 4 || !currentDate}
        />
        <CustomButton title={"Cancel"} secondaryButton onClick={onClose} />
      </div>
    </>
  );
};

export default AddAnnotationPopup;
