import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { Text } from "../../shared/uiComponents";
import { black900, red100, white100 } from "../../shared/Helpers/colors";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import { Container, Header } from "../../components/StyledComponents";
import { AdminTypes } from "../../components/Menu";
import { PERMISSIONS } from "../../App/constants";
import GraphReports from "./GraphReports";

import { useSelector } from "../../redux/store";

const Reports = () => {
  const role = useSelector((state) => state.account.role);

  if (!role) return <></>;
  if (role.section.id === AdminTypes.admin) return <ReportsForAdmin />;
  if (role.section.id === AdminTypes.bcba) return <GraphReports />;
  return <></>;
};

interface ReportsHeaderProperties {
  id: string;
  title: string;
  permissionGranted: boolean;
  url: string;
}

const ReportsForAdmin = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const MBH = useCheckPermission(PERMISSIONS.REPORT.MBH);
  const NC_DETAILED = useCheckPermission(PERMISSIONS.REPORT.NONCREDENTIALED);
  const NC_TOTAL = useCheckPermission(PERMISSIONS.REPORT.NONCREDENTIALED_TOTAL);
  const GRAPH = useCheckPermission(PERMISSIONS.REPORT.GRAPH);
  const SOAPNOTE = useCheckPermission(PERMISSIONS.REPORT.SOAPNOTE);
  const UTILIZATION = useCheckPermission(PERMISSIONS.REPORT.UTILIZATION);

  const [headers, setHeaders] = useState<Array<ReportsHeaderProperties>>([]);

  const reportHandler = (value: string) => {
    navigate(`/reports/${value}`);
  };

  useEffect(() => {
    const permissions = [
      {
        id: "0",
        title: "MBH",
        permissionGranted: MBH.permissionGranted,
        url: "mbh",
      },
      {
        id: "1",
        title: "Non Credentialed",
        permissionGranted:
          NC_DETAILED.permissionGranted || NC_TOTAL.permissionGranted,
        url: "nc",
      },
      {
        id: "2",
        title: "Graph",
        permissionGranted: GRAPH.permissionGranted,
        url: "graph",
      },
      {
        id: "3",
        title: "SOAP Notes",
        permissionGranted: SOAPNOTE.permissionGranted,
        url: "soap",
      },
      {
        id: "4",
        title: "Utilization",
        permissionGranted: UTILIZATION.permissionGranted,
        url: "utilization",
      },
      {
        id: "5",
        title: "Pending DSU",
        permissionGranted: true,
        url: "pending-dsu",
      },
      {
        id: "6",
        title: "Expired Sessions",
        permissionGranted: true,
        url: "expired-sessions",
      },
    ];

    setHeaders(permissions);
  }, [
    MBH.permissionGranted,
    SOAPNOTE.permissionGranted,
    GRAPH.permissionGranted,
    NC_DETAILED.permissionGranted,
    NC_TOTAL.permissionGranted,
    UTILIZATION.permissionGranted,
  ]);

  return (
    <Container>
      <Header>
        <Text title={"Reports"} size="mediumBold" />
      </Header>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
        {headers
          .filter((x) => x.permissionGranted)
          .map((header) => (
            <Text
              key={header.id}
              title={header.title}
              onClick={() => reportHandler(header.url)}
              size="smallBold"
              textColor={pathname.includes(header.url) ? white100 : black900}
              style={{
                border: "1px solid",
                borderRadius: "10px",
                padding: "8px 16px",
                backgroundColor: pathname.includes(header.url)
                  ? red100
                  : white100,
                borderColor: pathname.includes(header.url) ? red100 : white100,
              }}
            />
          ))}
      </div>
      <Outlet />
    </Container>
  );
};

export default Reports;
